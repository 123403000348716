import React, { useEffect, useMemo } from 'react'
import {
  Button,
  useShoppingCart,
  useLocation,
  Promotions,
  useCart,
  useResolution,
  getCustomerEmail,
  getAuth,
} from '@ecommerce/shared'
import { navigate } from 'gatsby'
import { useUI } from '@ecommerce/shared/src/context/UI/ui.context'
import { useCartStatus } from '@ecommerce/shared/src/context/ShoppingCart/cartStatusContext'
import secrets from '../../config/secrets'
import DotsLoader from '../DotsLoader'
import { Icon } from '../Icon/Icon'
import {
  CommonContainerPrices,
  Container,
  ContainerFooter,
  ContentContainer,
  HeaderContainer,
  ShowMore,
  Subtotal,
  Title,
  Wrapper,
} from './Summary.styled'
import AlertMessage from '../AlertMessage'

interface Props {
  isLoading: boolean
  isDisabled: boolean
  promotions?: Promotions
  className?: string
}

const Summary = (props: Props) => {
  const isInCartPage = window.location.pathname.includes('/cart')

  const isAuth = getAuth()
  const email = getCustomerEmail()

  const { isMobile } = useResolution()
  const { isLoadingTotalPrice, setIsLoadingTotalPrice } = useCartStatus()
  const { displayCartDrawer, closeDrawerCart, openSuggestedProducts } = useUI()

  const { state } = useShoppingCart()
  const { shippingCost, globalRawTotal, promotionsUpdatedAt, freeOver } = state

  const { toCurrency, textByCountry } = useLocation()

  const {
    showSummary,
    setShowSummary,
    loadingCheckout,
    submitOrder,
    getDiscounts,
    getTotalPrice,
    getShippingCost,
  } = useCart()

  const { className, isLoading, isDisabled, promotions } = props

  const totalDiscounts = getDiscounts()
  const minSubtotal = Number(secrets.MIN_RAW_TOTAL)
  const isSubtotalValid = getTotalPrice() >= minSubtotal
  const hasOnlyMerchandisingProducts = useMemo(
    () => Object.values(state.byHash).every((product) => product.categoryName === 'MERCHANDISING'),
    [state.byHash],
  )
  const hasProductWhitPromotionTag = useMemo(
    () => Object.values(state.byHash).some((product) => product.tags === 'promotion'),
    [state.byHash],
  )

  const canCheckout = isSubtotalValid && !isDisabled && !isLoading

  // Country settings
  const payButtonText = textByCountry('Ir a pagar', 'Realizar pedido')

  const getTextShowContent = () => {
    if (showSummary) return 'Ver menos'
    return 'Ver más'
  }

  const getIconIdShowContent = () => {
    if (showSummary) return 'chevron_up'
    return 'chevron_down'
  }

  useEffect(() => {
    setIsLoadingTotalPrice(false)
  }, [promotionsUpdatedAt])

  return (
    <Wrapper className={className}>
      <Container className="summary-container">
        <HeaderContainer>
          <Title className="summary-title">Resumen de mi pedido</Title>
          <ShowMore onClick={() => setShowSummary(!showSummary)}>
            <span>{getTextShowContent()}</span>
            <Icon fillColor="red" iconId={getIconIdShowContent()} size={16} />
          </ShowMore>
        </HeaderContainer>
        {!isSubtotalValid && (
          <AlertMessage type="error">
            El monto mínimo de compra es de <span className="min-subtotal">{toCurrency(minSubtotal)}</span>.
          </AlertMessage>
        )}
        {isSubtotalValid && hasProductWhitPromotionTag && hasOnlyMerchandisingProducts && (
          <AlertMessage type="error">
            <span className="min-subtotal">Agrega una bebida</span> para acceder a la promoción
          </AlertMessage>
        )}
        {showSummary && (
          <ContentContainer className="summary-content">
            <CommonContainerPrices>
              <span>Costo productos</span>
              <span>{toCurrency(globalRawTotal)}</span>
            </CommonContainerPrices>
            <CommonContainerPrices>
              <span>Descuentos</span>
              <span>{`-${toCurrency(totalDiscounts)}`}</span>
            </CommonContainerPrices>
            <CommonContainerPrices>
              <span>Despacho</span>
              <span>{toCurrency(Number(shippingCost))}</span>
            </CommonContainerPrices>
            {freeOver && Number(freeOver) > 0 ? (
              <CommonContainerPrices>
                <span>Despacho gratis desde {toCurrency(Number(freeOver))}</span>
                <span>{`-${toCurrency(getShippingCost().text)}`}</span>
              </CommonContainerPrices>
            ) : null}
          </ContentContainer>
        )}
        <Subtotal className="summary-subtotal">
          <span>Subtotal</span>
          {!isLoadingTotalPrice || Object.values(promotions ?? {}).length === 0 ? (
            <span data-testid="total-cart">{toCurrency(getTotalPrice())}</span>
          ) : (
            <div className="wrapper-loader">
              <DotsLoader />
            </div>
          )}
        </Subtotal>
        <ContainerFooter>
          <Button
            data-testid="go-pay"
            onClick={
              isInCartPage && isMobile
                ? () => openSuggestedProducts()
                : () => {
                    if (isInCartPage && !isMobile) {
                      submitOrder(undefined, email ?? undefined, isAuth)
                      return
                    }
                    closeDrawerCart()
                    navigate('/cart')
                  }
            }
            isLoading={isLoading || loadingCheckout}
            isDisabled={!canCheckout || loadingCheckout}
            disabled={!canCheckout || loadingCheckout}
          >
            {isInCartPage && !isMobile ? 'Continuar' : payButtonText}
          </Button>
          <Button
            btnType="tertiary"
            onClick={() => {
              navigate('/')
              if (displayCartDrawer) {
                closeDrawerCart()
              }
            }}
            isDisabled={loadingCheckout}
            disabled={loadingCheckout}
          >
            Seguir comprando
          </Button>
        </ContainerFooter>
      </Container>
    </Wrapper>
  )
}

export default Summary
