import { useShoppingCart } from '../context/ShoppingCart'

interface Props {
  skuCode: string
  quantity: number
}

export const useDisableAddProductButton = ({ skuCode, quantity }: Props) => {
  const {
    state: { byHash },
  } = useShoppingCart()

  const cartProduct = byHash[skuCode]
  const stock = cartProduct && cartProduct.stock ? cartProduct.stock : null

  const maxQuantity = cartProduct?.maxProductsPerCart ?? 30

  return ((maxQuantity && maxQuantity <= quantity) || !!(stock && stock <= quantity)) ?? false
}
