import {
  CleanCLOrder,
  CLLineItem,
  CLRetrieveOrderResponse,
  createOrder,
  getDiscountPercentage,
  getLastPendingOrDraftCustomerOrders,
  OrderStatus,
  ProductCart,
  ResourceType,
  ShoppingCartContext,
  toHash,
  getOrder,
  CLOrder,
  log,
  OrderAfterPayment,
  getStoredShippingCost,
} from '../..'
import {
  DiscountDetails,
  ShoppingCartInitialState,
  PromotionInteractionType,
  ShoppingCartState,
} from '../context/ShoppingCart/context'
import { getPricedSku } from '../services/BFF'
import { calculateCartTotals } from '../utils/prices'
import { Country, PromotionDetail, PromotionType } from '../types'
import { CLPrice } from '../services/CommerceLayer'

export const getByHashByLineItems = (lineItems: CLLineItem[]) => {
  return toHash(
    lineItems
      .filter(
        (lineItem) => lineItem.attributes.item_type === ResourceType.SKUS && !lineItem.attributes?.metadata?.isGift,
      )
      .map((lineItem) => {
        const {
          id,
          attributes: {
            quantity,
            image_url,
            sku_code,
            name,
            unit_amount_cents,
            metadata: {
              originalPrice,
              price,
              isBundle = false,
              recyclable = false,
              brandName,
              categoryName,
              isSuggested,
              size,
              labelId = '',
              labelUrl = '',
            },
          },
        } = lineItem
        let discount = 0
        if (originalPrice && price) {
          discount = getDiscountPercentage(price, originalPrice)
        }
        return {
          discount,
          hasDiscount: !!discount,
          image: image_url,
          isBundle,
          lineItemId: id,
          price: price ?? unit_amount_cents,
          quantity,
          rawPrice: originalPrice || price,
          recyclable,
          skuCode: sku_code,
          title: name,
          isSuggested: isSuggested ?? false,
          brandName: brandName ?? '',
          categoryName: categoryName ?? '',
          size: size ?? '',
          labelId,
          labelUrl,
        } as ProductCart
      }),
    'skuCode',
  )
}

export function lineItemsToCartState(
  lineItems: CLLineItem[],
  cartState?: ShoppingCartState,
): ShoppingCartContext['state'] {
  const byHash = getByHashByLineItems(lineItems)

  const discountDetails = lineItems
    .filter((lineItem) => lineItem.attributes.item_type === 'external_promotions')
    .map((discountDetail) => discountDetail.attributes.metadata.discountDetails)
    .flat() as DiscountDetails[]

  const promotions: Record<string, any> = {}

  discountDetails.forEach((detail) => {
    promotions[detail.sku] = {
      isGift: detail?.isGift ?? false,
      discountQuantity: detail?.discountQuantity ?? 0,
      discountAmount: (promotions[detail.sku] ? promotions[detail.sku].discountAmount : 0) + detail.discountAmount,
      names: promotions[detail.sku]
        ? [...promotions[detail.sku].names, detail.promotion.name]
        : [detail.promotion.name],
      labels: (promotions[detail.sku]
        ? [...promotions[detail.sku]?.labels, detail.promotion.shoppingCartLabel]
        : [detail.promotion.shoppingCartLabel]
      )?.filter(Boolean),
      promotion: {
        ...detail.promotion,
      },
    }
  })

  const giftLineItems = lineItems
    .filter((lineItem) => lineItem.attributes.item_type === ResourceType.SKUS && lineItem.attributes?.metadata?.isGift)
    .map((lineItem) => {
      const {
        id,
        attributes: { sku_code, quantity },
      } = lineItem

      return {
        sku: sku_code,
        ...cartState?.giftLineItems?.find((item) => item.sku === sku_code),
        lineItemId: id,
        ...promotions[sku_code],
        discountQuantity: quantity,
      }
    })

  const totalDiscount = discountDetails.reduce((total, detail) => total + (detail?.discountAmount ?? 0), 0)

  const globalSums = calculateCartTotals(byHash)
  return {
    byHash,
    ...globalSums,
    shippingCost: 0,
    orderId: 'removeMeLater',
    cartId: 'removeMeLater',
    couponCode: undefined,
    promotionCouponsWhitelist: [],
    giftLineItems,
    discountDetails,
    totalDiscount,
    promotions,
  }
}

export function extractLineItemsFromIncluded(included: CLRetrieveOrderResponse['included']): CLLineItem[] {
  return included?.filter((item): item is CLLineItem => item.type === 'line_items') || []
}

export function extractAdjustmentsFromIncluded(included: CLRetrieveOrderResponse['included']) {
  return included?.filter(
    (inc) =>
      inc.type === ResourceType.LINE_ITEMS &&
      inc.attributes.item_type === ResourceType.ADJUSTMENT &&
      inc.attributes.name === 'external-promotion-adjustment',
  ) as CLLineItem[]
}

const promotionItemTypes = [
  'fixed_amount_promotions',
  'percentage_discount_promotions',
  'external_promotions',
  'free_shipping',
]
export function extractPromotionsFromIncluded(included: CLRetrieveOrderResponse['included']): CLLineItem[] {
  return included?.filter((item): item is CLLineItem => promotionItemTypes.includes(item.type)) || []
}

function getGiftDiscountItems(lineItem: CLLineItem) {
  const data = lineItem?.attributes?.metadata?.discountDetails as PromotionDetail[]
  return data.filter((detail) => detail?.promotion?.type === PromotionType.A_DISCOUNTS_B)
}

function lineItemIsGift(lineItem: CLLineItem) {
  return !!(
    lineItem.attributes.item_type === ResourceType.EXTERNAL_PROMOTIONS &&
    lineItem?.attributes.metadata?.discountDetails &&
    getGiftDiscountItems(lineItem)?.length
  )
}

function extractGiftLineItems(lineItems: CLLineItem[]) {
  const itemsWithGifts = lineItems.filter(lineItemIsGift) || []
  const formattedGiftLineItems = itemsWithGifts
    .map<PromotionDetail[]>((lineItem) => {
      const discountItems = getGiftDiscountItems(lineItem)

      return (
        discountItems?.map((discountItem) => ({
          sku: discountItem?.sku,
          discountQuantity: discountItem?.discountQuantity ?? 0,
          isGift: !!discountItem,
          total: discountItem?.discountAmount,
          discountAmount: discountItem?.discountAmount,
        })) || []
      )
    })
    ?.flat()
  const totalGifts = Math.abs(formattedGiftLineItems.reduce((total, item) => total + (item?.total ?? 0), 0))

  return { totalGifts, formattedGiftLineItems, itemsWithGifts }
}

const getDiscountDetails = (lineItems: CLLineItem[]) => {
  const externalPromotionsAtribbutes = lineItems.find((item) => item.attributes.item_type === 'external_promotions')
    ?.attributes
  const giftCardDicountAtribbutes = lineItems.find((item) => item.attributes.item_type === 'gift_cards')?.attributes

  const giftCardDetails: DiscountDetails = {
    sku: 'giftcard',
    quantity: 1,
    discountAmount: Math.abs(giftCardDicountAtribbutes?.total_amount_cents ?? 0),
    discountQuantity: 0,
    balanceCents: 0,
    promotion: {
      uuid: PromotionType.GIFT_CARD,
      type: PromotionType.GIFT_CARD,
      name: 'Giftcard',
      interactionType: PromotionInteractionType.CUMULATIVE,
      isRaw: false,
    },
  }

  const discountDetails: DiscountDetails[] = [
    ...(externalPromotionsAtribbutes?.metadata.discountDetails
      ? (externalPromotionsAtribbutes?.metadata.discountDetails as DiscountDetails[])
      : []),
    giftCardDetails,
  ].filter((item) => (item.discountAmount ?? 0) > 0)

  return discountDetails
}

export async function getByHashByOrderId(country: Country, orderId: string) {
  const {
    data: { included },
  } = await getOrder({ orderId, country, include: 'line_items' })
  const lineItems = extractLineItemsFromIncluded(included)

  return getByHashByLineItems(lineItems)
}

export async function getCartStateByOrderId(
  marketNumber?: number,
  country?: Country,
  orderId?: string,
  cartId?: string,
): Promise<{ state: ShoppingCartState; gift_card_code?: string }> {
  if (orderId && country) {
    const {
      data: { included, data },
    } = await getOrder({ orderId, country, include: 'line_items' })
    const lineItems = extractLineItemsFromIncluded(included)

    const discountDetails = getDiscountDetails(lineItems)

    const { totalGifts } = extractGiftLineItems(lineItems)

    if (lineItems && lineItems.length) {
      return {
        state: {
          ...lineItemsToCartState(lineItems),
          shippingCost: data.attributes.shipping_amount_cents,
          freeOver: getStoredShippingCost()?.freeOver,
          couponCode: data.attributes.coupon_code,
          giftCard: data.attributes.gift_card_code,
          totalGifts,
          discountDetails,
          totalDiscount: discountDetails.reduce((total, { discountAmount }) => total + discountAmount, 0),
          ...(orderId ? { orderId } : {}),
          ...(cartId ? { cartId } : {}),
        },
      }
    }
  }

  return { state: ShoppingCartInitialState }
}

export async function createOrderAndGetId() {
  const {
    data: { id },
  } = await createOrder()
  return id
}

export const formatProductDetailData = (prices: CLPrice[]) => {
  const originalPrice = prices[0].attributes.compare_at_amount_cents
  const price = prices[0].attributes.amount_cents
  const discountPercentage =
    originalPrice && originalPrice !== price ? Math.round(((originalPrice - price) / originalPrice) * 100) : undefined

  return {
    price,
    originalPrice,
    rawPrice: originalPrice,
    hasDiscount: (originalPrice && originalPrice > price) || false,
    discount: discountPercentage,
  }
}

export const getProductDetailDataBySku = async (sku: string, marketNumber: number) => {
  log.trace('SKU')
  const {
    data: { data, included },
    request,
  } = await getPricedSku(sku, marketNumber)
  log.trace('SKU2')
  if (!data || !data.length || !included || !included.length) {
    const message = 'No results found for query'
    if (request && request.responseURL) throw new Error(`${message} ${request.responseURL}`)
    throw new Error(`${message} sku: ${sku}`)
  }

  return formatProductDetailData(included)
}

export const getOrderAfterPaymentData = async (
  orderId?: string,
  country?: Country,
): Promise<OrderAfterPayment | undefined> => {
  if (orderId && country) {
    const {
      data: {
        included,
        data: {
          attributes: {
            status,
            metadata,
            shipping_amount_cents,
            shipping_amount_float,
            total_amount_with_taxes_cents,
            total_amount_with_taxes_float,
            coupon_code,
            gift_card_code,
            number,
            skus_count,
          },
        },
      },
    } = await getOrder({ orderId, country, include: 'line_items' })
    const lineItems = extractLineItemsFromIncluded(included)
    const { byHash: items, globalRawTotal, globalTotalPromotion } = lineItemsToCartState(lineItems)

    if (lineItems && lineItems.length)
      return {
        items,
        status,
        total: globalRawTotal - (globalTotalPromotion ?? 0),
        metadata,
        number,
        shippingAmountCents: shipping_amount_cents,
        shippingAmountFloat: shipping_amount_float,
        clayerTotalCents: total_amount_with_taxes_cents,
        clayerTotalFloat: total_amount_with_taxes_float,
        couponCode: coupon_code ?? null,
        giftCardCode: gift_card_code ?? null,
        skus_count,
      }
  }
}
export const getCustomerLastFilteredOrder = async (customerEmail: string, marketNumber: number, country: Country) => {
  const orders = await getLastPendingOrDraftCustomerOrders(customerEmail, country, 20, marketNumber)
  const toCleanOrder = (order: CLOrder) => ({
    id: order.id,
    status: order.attributes.status,
    metadata: order.attributes.metadata,
  })
  const withCartId = orders
    .filter((order) => order && order.attributes.metadata && order.attributes.metadata.cartId)
    .reduce<{ pending: CleanCLOrder[]; draft: CleanCLOrder[] }>(
      (prev, order) => {
        return {
          pending: [...prev.pending, ...(order.attributes.status === OrderStatus.PENDING ? [toCleanOrder(order)] : [])],
          draft: [...prev.draft, ...(order.attributes.status === OrderStatus.DRAFT ? [toCleanOrder(order)] : [])],
        }
      },
      { pending: [], draft: [] },
    )
  return withCartId
}

export const getOrderShippingDate = (order: CLOrder) =>
  order.relationships.shipments.objects?.length
    ? order.relationships.shipments.objects[0].attributes.metadata.shippingDate
    : ''
