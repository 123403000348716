import styled from 'styled-components'
import { breakpoints, hexToRGBA } from '@ecommerce/shared'

export const SummaryCartContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};
  border-radius: 4px;
  padding-inline: 24px;
  padding-block-start: 24px;
  padding-block-end: 48px;
  min-width: 300px;

  .products-cost,
  .shipping-cost,
  .subtotal {
    color: ${({ theme }) => theme.colors.platinum80};
  }

  .discount,
  .discount-2 {
    color: ${({ theme }) => theme.colors.green};
  }

  .subtotal {
    font-weight: bold;
    font-size: 17px;
    line-height: 25px;
    letter-spacing: -0.24px;
    margin-block-end: 40px;
  }

  @media screen and (${breakpoints.desktopWide.min}) {
    min-width: 359px;
  }
`

export const TitleContainer = styled.div`
  padding-block-end: 16px;
  margin-block-end: 32px;
  border-block-end: 1px solid ${({ theme }) => theme.colors.platinum20};
`

export const Title = styled.h4`
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.254118px;
`

export const PricesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-block-end: 16px;
  border-block-end: 1px solid ${({ theme }) => theme.colors.platinum20};
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.06px;
  margin-block-end: 24px;
`

export const PriceCommonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .wrapper-loader {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-inline-end: 16px;
  }
`

export const AlertWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: 8px;
  height: 32px;
  background: ${({ theme }) => hexToRGBA(theme.colors.red20, 0.1)};
  border-radius: 4px;
  border-left: 4px solid ${({ theme }) => theme.colors.secondary};
  margin-block-end: 16px;
  text-align: start;

  .alert-icon {
    margin-left: 4px;
  }
`

export const AlertMessage = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.secondary};

  .min-subtotal {
    font-weight: bold;
  }
`

export const WrapperButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
