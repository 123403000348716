import {
  Product,
  ProductCart,
  ActionApplyCoupon,
  ActionRemoveCoupon,
  PromotionDetail,
  ProductPromotion,
  ActionApplyGiftCard,
  ActionRemoveGiftCard,
} from '../../types'
import { ShoppingDispatch, ShoppingCartState, ShoppingCartInitialState } from './context'
import { deleteLineItem } from '../../services/BFF/lineItems'
import {
  applyCoupon,
  removeCoupon,
  removeGiftcard,
  applyGiftCard,
  getSkuStock,
  updateOrderLineItem,
} from '../../services/BFF'
import { getStoredMarket, CartStockErrorHandler, getStoredDistributionCenter } from '../../..'
import { sendMessageToSentry, ErrorLevel, ErrorSource } from '../../../../../apps/cl-customer-webapp/src/utils/sentry'
import config from '../../config'

const { COUNTRY, MIN_GIFT_CARD_CODE_LENGTH } = config

export type StockErrorHandler = (props: { product: ProductCart; stock: number }) => void
const emptyProduct: ProductCart = {
  skuCode: '0',
  image: '',
  title: '',
  price: 0,
  rawPrice: 0,
  hasDiscount: false,
  discount: 0,
  recyclable: false,
  unavailable: false,
  isBundle: false,
  brandName: '',
  categoryName: '',
  packing: '',
  size: '',
  slugLocation: '',
  quantity: 0,
  lineItemId: '',
  labelId: '',
  labelUrl: '',
  presale: false,
  isSuggested: false,
  netContent: 0,
  stock: 0,
  maxProductsPerCart: 30,
  tags: undefined,
}

// Formatter
export const productToStateFormat = (product: Product & { quantity?: number; lineItemId?: string }) =>
  Object.entries(product)
    .filter(([key]) => Object.keys(emptyProduct).includes(key))
    .reduce<ProductCart>((obj, [key, value]) => ({ ...obj, ...{ [key]: value ?? null } }), emptyProduct)

export function actionSetItemStock(dispatch: ShoppingDispatch) {
  return (skuCode: string, stock: number) => {
    dispatch({ type: 'SET_ITEM_STOCK', skuCode, stock })
  }
}

export function actionRemoveProduct(dispatch: ShoppingDispatch) {
  return (skuCode: string) =>
    dispatch({
      type: 'REMOVE_PRODUCT',
      skuCode,
    })
}

const updateProductCommerceLayer = async (
  orderId: string,
  lineItemId: string,
  quantity: number,
  onUpdateCartState?: () => void,
) => {
  const market = getStoredMarket()
  const distributionCenter = getStoredDistributionCenter()
  await updateOrderLineItem(orderId, lineItemId, quantity, COUNTRY, market?.slug ?? '', distributionCenter?.slug ?? '')

  if (onUpdateCartState) {
    onUpdateCartState()
  }
}

export function actionAddProduct(dispatch: ShoppingDispatch) {
  return (state: ShoppingCartState) => async (
    params: { product: Product; quantity?: number; promotion?: ProductPromotion },
    stockErrorHandler?: CartStockErrorHandler,
    lineItemId?: string | null,
    orderId?: string | null,
    onUpdateCartState?: () => void,
  ) => {
    const { product, promotion } = params
    const { byHash } = state
    const quantity = params.quantity ?? 1
    const storedProduct = byHash[product.skuCode] || undefined
    if (
      storedProduct &&
      storedProduct.stock &&
      storedProduct.quantity + quantity > storedProduct.stock &&
      stockErrorHandler
    ) {
      stockErrorHandler({ product: storedProduct, stock: storedProduct.stock })

      // Update commerce layer if product has line item reference
      if (lineItemId && orderId && storedProduct.stock > 0) {
        await updateProductCommerceLayer(orderId, lineItemId, storedProduct.stock, onUpdateCartState)
      }

      dispatch({
        type: 'SET_PRODUCT_QUANTITY',
        skuCode: storedProduct.skuCode,
        quantity: storedProduct.stock,
      })
      return
    }

    const maxQuantity = product.maxProductsPerCart

    if (maxQuantity && (quantity > maxQuantity || (storedProduct && storedProduct.quantity + quantity > maxQuantity))) {
      if (lineItemId && orderId && maxQuantity > 0) {
        await updateProductCommerceLayer(orderId, lineItemId, maxQuantity, onUpdateCartState)
      }

      dispatch({
        type: 'SET_PRODUCT_QUANTITY',
        skuCode: storedProduct.skuCode,
        quantity: maxQuantity,
      })
      return
    }

    dispatch({
      type: 'ADD_PRODUCT',
      product: productToStateFormat(product),
      quantity,
      promotion: promotion ?? null,
    })

    if (lineItemId && orderId && quantity > 0) {
      await updateProductCommerceLayer(orderId, lineItemId, storedProduct.quantity + quantity, onUpdateCartState)
    }

    if (!storedProduct || storedProduct.stock === undefined) {
      const market = getStoredMarket()
      const storedDistributionCenter = getStoredDistributionCenter()
      if (
        market &&
        storedDistributionCenter &&
        storedDistributionCenter.commerceLayer.stockLocation &&
        storedDistributionCenter.commerceLayer.stockLocation.number
      ) {
        const stockLocation = storedDistributionCenter.commerceLayer.stockLocation.number

        getSkuStock(product.skuCode, COUNTRY, stockLocation)
          .then(({ quantity: stock }) => {
            if (stock) actionSetItemStock(dispatch)(product.skuCode, stock)
            if (stock === 0 && stockErrorHandler) {
              stockErrorHandler({
                product: { ...product, quantity, discount: product.discount || 0 },
                stock,
              })
              actionRemoveProduct(dispatch)(product.skuCode)
            }
          })
          .catch((e) => {
            sendMessageToSentry({
              message: `Error fetching max stock for sku ${product.skuCode}`,
              page: 'context - actionAddProduct',
              source: ErrorSource.CLayer,
              level: ErrorLevel.Warning,
              tags: { 'incident.type': 'stock' },
              metadata: {
                error: e?.response ?? e,
                product,
                quantity_to_add: quantity,
              },
            })
          })
      }
    }
  }
}

export function actionSubtractProduct(dispatch: ShoppingDispatch) {
  return (skuCode: string, quantity?: number) =>
    dispatch({
      type: 'SUBTRACT_PRODUCT',
      skuCode,
      quantity,
    })
}

export function actionSubtractProductAsync(dispatch: ShoppingDispatch) {
  return (state: ShoppingCartState) => async (
    skuCode: string,
    quantity?: number,
    lineItemId?: string | null,
    orderId?: string | null,
    onUpdateCartState?: () => void,
  ) => {
    const { byHash } = state
    const storedProduct = byHash[skuCode] || undefined

    if (lineItemId && orderId && storedProduct && storedProduct.quantity && quantity) {
      await updateProductCommerceLayer(orderId, lineItemId, storedProduct.quantity - quantity, onUpdateCartState)
    }

    dispatch({
      type: 'SUBTRACT_PRODUCT',
      skuCode,
      quantity,
    })
  }
}

export function actionReplaceState(dispatch: ShoppingDispatch) {
  return (state: ShoppingCartState) => {
    dispatch({ type: 'REPLACE_STATE', state })
  }
}

export function actionEmpty(dispatch: ShoppingDispatch) {
  return () => {
    dispatch({ type: 'EMPTY' })
  }
}

export function actionReset(dispatch: ShoppingDispatch) {
  return () => {
    dispatch({ type: 'RESET' })
  }
}

export function actionRemoveProductAsync(dispatch: ShoppingDispatch) {
  return async ({ skuCode, lineItemId }: ProductCart, orderId = '', onUpdateCartState?: () => void) => {
    if (lineItemId) {
      await updateProductCommerceLayer(orderId, lineItemId, 0, onUpdateCartState)

      return dispatch({ type: 'REMOVE_PRODUCT_ASYNC', skuCode })
    }
  }
}

export function actionRemoveGiftAsync(dispatch: ShoppingDispatch) {
  return async (giftLineItems: PromotionDetail[]) => {
    giftLineItems.map(async (item) => {
      const { sku, lineItemId } = item
      if (lineItemId && sku) {
        await deleteLineItem({ lineItemId })

        return dispatch({ type: 'REMOVE_GIFT_ASYNC', sku })
      }
    })
  }
}

export const actionApplyCouponOrGiftCard: (
  dispatch: ShoppingDispatch,
  state: ShoppingCartState,
) => ActionApplyCoupon = (dispatch, { promotionCouponsWhitelist }) => {
  return async ({ code, orderId, country, currentMarket, distributionCenterSlug }) => {
    const distributionCenter = getStoredDistributionCenter()
    const market = distributionCenter?.internalName ?? ''
    const marketNumber = distributionCenter?.commerceLayer.market?.number

    if (marketNumber) {
      const { discountedAmount, isRaw, discountDetails } = await applyCoupon({
        code,
        orderId,
        country,
        market,
        currentMarket,
        distributionCenterSlug,
      })
      if (discountDetails)
        dispatch({
          type: 'APPLY_COUPON',
          amount: discountedAmount,
          code,
          isRawDiscount: isRaw ?? false,
          discountDetails,
        })
      return { discountedAmount, isRaw }
    }
  }
}

export const actionApplyGiftCard: (dispatch: ShoppingDispatch, state: ShoppingCartState) => ActionApplyGiftCard = (
  dispatch,
) => {
  return async ({ giftCard, orderId, country, currentMarket }) => {
    const distributionCenter = getStoredDistributionCenter()
    const marketNumber = distributionCenter?.commerceLayer.market.number

    if (marketNumber) {
      const { discountedAmount, isRaw, discountDetails } = await applyGiftCard({
        giftCard,
        orderId,
        country,
        marketNumber,
        currentMarket,
      })
      if (discountDetails) {
        dispatch({
          type: 'APPLY_GIFT_CARD',
          amount: discountedAmount,
          giftCard,
          isRawDiscount: isRaw ?? false,
          discountDetails,
        })
      }
      return { discountedAmount, isRaw }
    }
  }
}

export const actionRemoveCouponOrGiftCard: (
  dispatch: ShoppingDispatch,
  state: ShoppingCartState,
) => ActionRemoveCoupon = (dispatch, { promotionCouponsWhitelist }) => {
  return async ({ orderId, country, code, currentMarket, distributionCenterSlug }) => {
    const handler =
      promotionCouponsWhitelist.includes(code) || code.length < MIN_GIFT_CARD_CODE_LENGTH
        ? removeCoupon
        : removeGiftcard
    await handler({ orderId, country, currentMarket, distributionCenterSlug })
    return dispatch({ type: 'REMOVE_COUPON' })
  }
}

export const actionRemoveGiftCard: (dispatch: ShoppingDispatch, state: ShoppingCartState) => ActionRemoveGiftCard = (
  dispatch,
) => {
  return async ({ orderId, country, currentMarket, distributionCenterSlug }) => {
    await removeGiftcard({
      orderId,
      country,
      currentMarket,
      distributionCenterSlug,
    })
    return dispatch({ type: 'REMOVE_GIFT_CARD' })
  }
}

export function actionSetCartId(dispatch: ShoppingDispatch) {
  return (cartId: string) => {
    dispatch({ type: 'SET_CART_ID', cartId })
  }
}
