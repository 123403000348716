import { Button, useAuth, useCart, useLocation, useShoppingCart } from '@ecommerce/shared'
import React, { Fragment, useEffect, useMemo } from 'react'
import { navigate } from 'gatsby'
import { useCartStatus } from '@ecommerce/shared/src/context/ShoppingCart/cartStatusContext'
import {
  AlertMessage,
  AlertWrapper,
  PriceCommonContainer,
  PricesContainer,
  SummaryCartContainer,
  Title,
  TitleContainer,
  WrapperButtons,
} from './styled'
import { Icon } from '../../../components/Icon/Icon'
import secrets from '../../../config/secrets'
import DotsLoader from '../../../components/DotsLoader'

export const SummaryCart = () => {
  const {
    state: { isAuth },
  } = useAuth()

  const {
    state: { globalRawTotal, freeOver, promotionsUpdatedAt, shippingCost, byHash },
  } = useShoppingCart()

  const { isLoadingTotalPrice, setIsLoadingTotalPrice } = useCartStatus()

  const { getDiscounts, getShippingCost, getTotalPrice, submitOrder, loadingCheckout } = useCart()

  const { toCurrency } = useLocation()

  const totalDiscounts = getDiscounts()

  const minSubtotal = Number(secrets.MIN_RAW_TOTAL)
  const isSubtotalValid = getTotalPrice() >= minSubtotal
  const hasOnlyMerchandisingProducts = useMemo(
    () => Object.values(byHash).every((product) => product.categoryName === 'MERCHANDISING'),
    [byHash],
  )
  const hasProductWhitPromotionTag = useMemo(
    () => Object.values(byHash).some((product) => product.tags === 'promotion'),
    [byHash],
  )

  useEffect(() => {
    setIsLoadingTotalPrice(false)
  }, [promotionsUpdatedAt])

  return (
    <Fragment>
      <SummaryCartContainer>
        <TitleContainer className="title-container">
          <Title className="title">Resumen de mi pedido</Title>
        </TitleContainer>
        <PricesContainer className="prices-container">
          <PriceCommonContainer className="products-cost">
            <span>Costo productos</span>
            <span>{toCurrency(globalRawTotal)}</span>
          </PriceCommonContainer>
          {totalDiscounts > 0 && (
            <PriceCommonContainer className="discount">
              <span>Descuentos</span>
              <span>-{toCurrency(totalDiscounts)}</span>
            </PriceCommonContainer>
          )}
          <PriceCommonContainer className="shipping-cost">
            <span>Despacho</span>
            <span>{toCurrency(Number(shippingCost))}</span>
          </PriceCommonContainer>
          {freeOver && freeOver > 0 ? (
            <PriceCommonContainer className="discount">
              <span>Gratis desde {toCurrency(freeOver)}</span>
              <span>-{toCurrency(getShippingCost().text)}</span>
            </PriceCommonContainer>
          ) : null}
        </PricesContainer>
        <PriceCommonContainer className="subtotal">
          {!isLoadingTotalPrice ? (
            <>
              <span>Subtotal</span>
              <span>{toCurrency(getTotalPrice())}</span>
            </>
          ) : (
            <div className="wrapper-loader">
              <DotsLoader className="loader" />
            </div>
          )}
        </PriceCommonContainer>
        {!isSubtotalValid && (
          <AlertWrapper className="alert">
            <Icon className="alert-icon" iconId="info_outline" size="16" fillColor="secondary" />
            <AlertMessage className="alert-message">
              El monto mínimo de compra es de <span className="min-subtotal">{toCurrency(minSubtotal)}</span>.
            </AlertMessage>
          </AlertWrapper>
        )}
        {isSubtotalValid && hasProductWhitPromotionTag && hasOnlyMerchandisingProducts && (
          <AlertWrapper className="alert">
            <Icon className="alert-icon" iconId="info_outline" size="16" fillColor="secondary" />
            <AlertMessage className="alert-message">
              <span className="min-subtotal">Agrega una bebida</span> para acceder a la promoción
            </AlertMessage>
          </AlertWrapper>
        )}
        <WrapperButtons className="wrapper-buttons">
          <Button
            onClick={() => submitOrder(undefined, undefined, isAuth)}
            btnType="primary"
            type="button"
            isLoading={loadingCheckout}
            isDisabled={!isSubtotalValid || loadingCheckout}
            disabled={!isSubtotalValid || loadingCheckout}
          >
            Continuar
          </Button>

          <Button onClick={() => navigate('/')} title="Seguir comprando" btnType="secondary">
            Seguir comprando
          </Button>
        </WrapperButtons>
      </SummaryCartContainer>
    </Fragment>
  )
}
