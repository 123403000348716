import { log } from '@ecommerce/shared'

declare const window: Window & { connectif?: any }

type ProductType = {
  name: string
  productDetailUrl: string
  productId: string
  unitPrice: number
  availability: string
  imageUrl: string | null
  brand?: string
  quantity?: number
  price?: number
  unitPriceOriginal: number
  unitPriceWithoutVAT?: number
  discountedAmount?: number
  discountedPercentage?: number
  categories: (string | undefined)[]
}

export type CartType = {
  cartId: string
  totalQuantity: number
  totalPrice: number
  products: ProductType[]
} | null

export type OrderType = {
  totalQuantity: number
  totalPrice: number
  purchaseId: string
  products: ProductType[]
}

export type PageType = {
  title: string
}

export type EntityInfoType = {
  primaryKey: string
  _name: string
  _email: string
  _dni: string
  _birthdate: string
  _city?: string
  invitado: boolean
  rut?: string
  region?: string
  comuna?: string
} | null

export const sendPageVisitToConnectif = (cart: CartType, pageInfo: PageType, entityInfo: EntityInfoType) => {
  if (window && window.connectif && window.connectif.managed && window.connectif.managed.isInitialized()) {
    log.trace(`Sending page visit to connectif ${pageInfo}`)
    window.connectif.managed.sendEvents([{ type: 'page-visit' }], {
      entityInfo,
      cart,
      pageInfo,
    })
  }
}

export const sendProductVisitToConnectif = (cart: CartType, product: ProductType, entityInfo: EntityInfoType) => {
  if (window && window.connectif && window.connectif.managed && window.connectif.managed.isInitialized()) {
    log.trace('Sending send product page visited to connectif', { cart, product, entityInfo })
    window.connectif.managed.sendEvents(
      [
        {
          type: 'product-visited',
          product,
        },
        { type: 'page-visit' },
      ],
      {
        entityInfo,
        cart,
      },
    )
  }
}

export const sendSuccessRegisterToConnectif = (cart: CartType, pageInfo: PageType, entityInfo: EntityInfoType) => {
  if (window && window.connectif && window.connectif.managed && window.connectif.managed.isInitialized()) {
    log.trace('Sending success register to connectif')
    window.connectif.managed.sendEvents([{ type: 'page-visit' }, { type: 'register' }], {
      entityInfo,
      cart,
      pageInfo,
    })
  }
}

export const sendLoginToConnectif = (cart: CartType, entityInfo: EntityInfoType) => {
  if (window && window.connectif && window.connectif.managed && window.connectif.managed.isInitialized()) {
    log.trace('Sending login to connectif')
    window.connectif.managed.sendEvents([{ type: 'login' }], {
      entityInfo,
      cart,
    })
  }
}

export const sendSearchToConnectif = (cart: CartType, searchText: string, entityInfo: EntityInfoType) => {
  if (window && window.connectif && window.connectif.managed && window.connectif.managed.isInitialized()) {
    log.trace('Sending search to connectif')
    window.connectif.managed.sendEvents([{ type: 'search', searchText }], {
      entityInfo,
      cart,
    })
  }
}

export const sendSuccessPurchaseToConnectif = (purchase: OrderType, entityInfo: EntityInfoType) => {
  if (window && window.connectif && window.connectif.managed && window.connectif.managed.isInitialized()) {
    log.trace('Sending success purchase to connectif')
    window.connectif.managed.sendEvents(
      [
        {
          type: 'purchase',
          purchase,
        },
        {
          type: 'page-visit',
        },
      ],
      {
        entityInfo,
      },
    )
  }
}

export const sendCartDataToConnectif = (cart: CartType, entityInfo: EntityInfoType) => {
  if (window && window.connectif && window.connectif.managed && window.connectif.managed.isInitialized()) {
    log.trace('Sending cart data to connectif')
    window.connectif.managed.sendEvents([], {
      entityInfo,
      cart,
    })
  }
}
