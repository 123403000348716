import React, { ReactNode, useEffect, useReducer, useRef } from 'react'
import secrets from '@ecommerce/chile-customer-webapp/src/config/secrets'
import { useCartStockErrorHandler } from '@ecommerce/chile-customer-webapp/src/utils/errors'
import { PromotionType } from '@ecommerce/customer-bff/src/types'
import { generateProductUrl } from '@ecommerce/chile-customer-webapp/src/config/siteBuild/utils'
import {
  DispatchContext,
  ShoppingCart,
  ShoppingCartContext,
  ShoppingCartInitialState,
  ShoppingCartState,
} from './context'
import {
  actionAddProduct,
  actionApplyCouponOrGiftCard,
  actionApplyGiftCard,
  actionEmpty,
  actionRemoveCouponOrGiftCard,
  actionRemoveGiftAsync,
  actionRemoveGiftCard,
  actionRemoveProduct,
  actionRemoveProductAsync,
  actionReplaceState,
  actionReset,
  actionSetCartId,
  actionSubtractProduct,
  actionSubtractProductAsync,
  productToStateFormat,
  StockErrorHandler,
} from './actions'
import middleware from './middleware'
import {
  getCartId,
  getProductsBySkus,
  getStoredMarket,
  getStoredShippingCost,
  ProductCart,
  Promotions,
  sendAddProductToGTM,
  sendRemoveProductToGTM,
  setCartId,
  useLocation,
} from '../../..'
import { getAuth, getStoredDistributionCenter } from '../../utils/store'
import { useFirestore } from '../../hooks/useFirestore'
import { useFirebase } from '../Firebase'
import { ErrorLevel, ErrorSource, sendMessageToSentry } from '../../../../../apps/cl-customer-webapp/src/utils/sentry'
import { debounce } from '../../utils/debounce'
import { log } from '../../utils/log'
import { useCartStatus } from './cartStatusContext'

export function ShoppingCartProvider(props: {
  shippingCost: number
  children: ReactNode
  promotionCouponsWhitelist?: string[]
  freeOver: number
}) {
  const { children, promotionCouponsWhitelist, shippingCost, freeOver } = props

  const cartId = getCartId()

  const {
    state: { firebaseInstance },
  } = useFirebase()

  const {
    fsShoppingCartSubscription,
    objectToShoppingCart,
    createFsShoppingCart,
    pushCartStateToFirestore,
  } = useFirestore()

  const initialState = {
    ...ShoppingCartInitialState,
    shippingCost,
    freeOver,
    cartId: cartId ?? '',
    promotionCouponsWhitelist: promotionCouponsWhitelist ?? [],
  }

  const pushToFirestore = useRef(pushCartStateToFirestore)

  const [state, dispatch] = useReducer(middleware(pushToFirestore.current), initialState)

  const createShoppingCart = (cartState: ShoppingCartState, resetCart = false) => {
    return createFsShoppingCart(cartState)
      .then((id) => {
        setCartId(id)
        if (resetCart) actionReplaceState(dispatch)(initialState)
        actionSetCartId(dispatch)(id)
      })
      .catch((e) => {
        log.error('error', e.message)
        if (e && e.code === 'permission-denied') return
        sendMessageToSentry({
          message: `Firestore: Failed to create cart`,
          page: 'context - cart index - createShoppingCart',
          source: ErrorSource.Firestore,
          level: ErrorLevel.Error,
          metadata: {
            error: e,
            cartState,
            resetCart,
            marketSlug: getStoredMarket()?.slug ?? '',
            distributionCenterSlug: getStoredDistributionCenter()?.slug ?? '',
          },
        })
      })
  }

  useEffect(() => {
    pushToFirestore.current = debounce(pushCartStateToFirestore, 200)
    if (!cartId && !getAuth() && firebaseInstance) {
      createShoppingCart(state)
    }
  }, [firebaseInstance])

  useEffect(() => {
    setCartId(state.cartId)
    if (!firebaseInstance) return
    if (state.cartId) {
      try {
        const unsubscribe = fsShoppingCartSubscription(state.cartId, (doc) => {
          const data = doc.data()
          if (!data || (data.status && data.status === 'placed')) {
            const storedId = getCartId()
            if (!storedId || state.cartId === storedId) createShoppingCart(initialState, true)
            else actionSetCartId(dispatch)(storedId)
          } else {
            const newState = {
              ...state,
              ...objectToShoppingCart(data),
              shippingCost: getStoredShippingCost()?.shippingCost,
              freeOver: getStoredShippingCost()?.freeOver,
            }
            actionReplaceState(dispatch)(newState)
          }
        })
        return unsubscribe
      } catch (e) {
        sendMessageToSentry({
          message: `Firestore: Failed subscription for cart ${state.cartId}`,
          page: 'context - cart index',
          source: ErrorSource.Firestore,
          level: ErrorLevel.Warning,
          metadata: {
            cartId: state.cartId,
            error: e,
          },
        })
        createShoppingCart(state)
      }
    }
  }, [state.cartId, firebaseInstance])

  return (
    <ShoppingCartContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </ShoppingCartContext.Provider>
  )
}

export function useShoppingCart() {
  const state = React.useContext(ShoppingCartContext)

  const dispatch = React.useContext(DispatchContext)

  const { isBolivia } = useLocation()
  const { setIsLoadingTotalPrice } = useCartStatus()
  const stockErrorHandler = useCartStockErrorHandler()
  const { createFsShoppingCart, pushCartStateToFirestore } = useFirestore()

  const distributionCenter = getStoredDistributionCenter()

  if (state === undefined || dispatch === undefined) {
    throw new Error('useShoppingCart must be used within a ShoppingCartProvider')
  }

  const createShoppingCart = async (cartState = ShoppingCartInitialState) => {
    const cartId = await createFsShoppingCart(cartState)
    return cartId
  }

  const createShoppingCartAndSetId = async (cartState = ShoppingCartInitialState) => {
    const cartId = await createShoppingCart(cartState)
    actionSetCartId(dispatch)(cartId)
    return cartId
  }

  const getOrderId = () => {
    return state.orderId
  }

  const getProducts = () => {
    const skus = Object.keys(state.byHash)
    return skus.map((sku) => state.byHash[sku])
  }

  const getAlgoliaProducts = async ({ slugLocation }: { slugLocation: string }) => {
    const skus = Object.keys(state.byHash)
    const algoliaProducts = await getProductsBySkus({ slugLocation, skus })
    return algoliaProducts
  }

  const getGiftDiscounts = (promotions?: Promotions) => {
    return (
      Object.keys(promotions ?? [])
        .map((sku) => promotions && promotions[sku])
        .reduce(
          (acc, promotion) =>
            acc + (promotion?.promotion?.type === PromotionType.A_DISCOUNTS_B ? promotion?.discountAmount ?? 0 : 0),
          0,
        ) ?? 0
    )
  }

  const getDiscounts = () => {
    return state.totalDiscount && state.totalDiscount > 0
      ? state.totalDiscount - getGiftDiscounts(state.promotions)
      : state.globalTotalDiscounted ?? 0
  }

  const getTotal = () => {
    const total = state.globalRawTotal + Number(state.shippingCost) - (getDiscounts() ?? 0)
    return total
  }

  const getConnectifCart = () => {
    const url = `${document.location.origin}`
    return state.cartId
      ? {
          cartId: state.cartId,
          totalQuantity: state.globalQuantity,
          totalPrice: state.globalRawTotal - (state.globalTotalPromotion ?? 0),
          products: Object.values(state.byHash).map((product) => ({
            name: product.title,
            productDetailUrl: `${url}/products/${generateProductUrl(product.title, product.skuCode)}`,
            productId: product.skuCode?.toString(),
            unitPrice: product.price,
            availability: !product.unavailable ? 'InStock' : 'outofstock',
            imageUrl: product.image,
            brand: product.brandName,
            quantity: product.quantity,
            price: product.price * product.quantity,
            unitPriceOriginal: product.rawPrice,
            unitPriceWithoutVAT: product.price,
            discountedAmount: product.rawPrice - product.price,
            discountedPercentage: product.discount,
            categories: [product.categoryName],
          })),
        }
      : null
  }

  const updateProduct = async (
    product: ProductCart,
    productQuantity: number,
    orderId?: string,
    onUpdateCartState?: () => void,
  ) => {
    const {
      discount,
      hasDiscount,
      image,
      isGift,
      lineItemId,
      price,
      quantity,
      rawPrice,
      recyclable,
      skuCode,
      title,
      unavailable,
      netContent,
      stock,
      maxProductsPerCart,
      tags,
    } = product
    setIsLoadingTotalPrice(true)

    if (productQuantity - product.quantity > 0 && !product.isGift) {
      sendAddProductToGTM(product, isBolivia())
      await actionAddProduct(dispatch)(state)(
        {
          product: {
            skuCode,
            image,
            title,
            rawPrice,
            price,
            discount,
            recyclable,
            unavailable,
            hasDiscount,
            netContent,
            stock,
            maxProductsPerCart: maxProductsPerCart || 30,
            tags,
          },
          quantity: productQuantity - quantity,
        },
        stockErrorHandler,
        lineItemId,
        orderId,
        onUpdateCartState,
      )
      return
    }

    if (quantity - productQuantity > 0 && !isGift) {
      sendRemoveProductToGTM([{ ...product, quantity: quantity - productQuantity }], isBolivia())
      await actionSubtractProductAsync(dispatch)(state)(
        skuCode,
        quantity - productQuantity,
        lineItemId,
        orderId,
        onUpdateCartState,
      )
    }
  }

  const removeProduct = async (product: ProductCart, orderId?: string) => {
    setIsLoadingTotalPrice(true)

    sendRemoveProductToGTM([product], isBolivia())

    if (product.lineItemId) {
      await actionRemoveProductAsync(dispatch)(product, orderId)
      return
    }
    actionRemoveProduct(dispatch)(product.skuCode)
  }

  const revalidateOrder = async () => {
    const cartId = getCartId()
    if (cartId && distributionCenter) {
      const newState = { ...state, couponCode: '', orderId: '' }
      const skusCode = Object.keys(state.byHash)
      const products = await getProductsBySkus({ slugLocation: distributionCenter?.slug, skus: skusCode })

      newState.byHash = {}
      products.forEach((product) => {
        newState.byHash[product.skuCode] = {
          ...productToStateFormat(product),
          rawPrice: product.originalPrice,
          quantity: state.byHash[product.skuCode].quantity,
          promotion: product.promotion ?? null,
        }
      })

      actionReplaceState(dispatch)(newState)
      pushCartStateToFirestore(cartId, newState)
    }
  }

  return {
    state,
    getConnectifCart,
    addProduct: actionAddProduct(dispatch)(state),
    subtractProduct: actionSubtractProduct(dispatch),
    subtractProductAsync: actionSubtractProductAsync(dispatch)(state),
    removeProductAsync: actionRemoveProductAsync(dispatch),
    removeGiftAsync: actionRemoveGiftAsync(dispatch),
    replaceState: actionReplaceState(dispatch),
    setCartId: actionSetCartId(dispatch),
    createShoppingCart,
    createShoppingCartAndSetId,
    empty: actionEmpty(dispatch),
    reset: actionReset(dispatch),
    applyCouponOrGiftCard: actionApplyCouponOrGiftCard(dispatch, state),
    applyGiftCard: actionApplyGiftCard(dispatch, state),
    removeCouponOrGiftCard: actionRemoveCouponOrGiftCard(dispatch, state),
    removeGiftCard: actionRemoveGiftCard(dispatch, state),
    getProducts,
    getAlgoliaProducts,
    getOrderId,
    removeProduct,
    updateProduct,
    getDiscounts,
    getTotal,
    revalidateOrder,
  }
}

export type ShoppingCartContext = ReturnType<typeof useShoppingCart>
export type CartStockErrorHandler = StockErrorHandler
export type ShoppingCartModel = ShoppingCart
