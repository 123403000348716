import React from 'react'
import { FormattedProduct } from '../services/Algolia'
import { Market, Zone } from '../services/Location/cities'
import { OrderStatus } from '../../../../apps/customer-bff/src/services/CommerceLayer/types'
import { CLOrder } from '../services/CommerceLayer'

export type Error = {
  status?: number
  code: string
  description?: string
  metadata?: string
}

export type SelectOption<T = any> = {
  value: T
  label: string
}

export enum Country {
  CHILE = 'CL',
  BOLIVIA = 'BO',
}

export type CommerceLayerAddress = {
  id: string
  attributes: {
    line_1: string
    line_2?: string
    city: string
    phone: string
    first_name: string
    last_name: string
    full_name: string
    country_code: string
    state_code: string
    zip_code: string
    lat?: number
    lng?: number
    notes?: string
    metadata: {
      alias: string
      marketId: number
      marketName: string
      favorite: boolean
      zoneId: number
      zoneName: string
      distributionCenterId?: number
    }
  }
  relationships?: {
    address: {
      data: {
        id: string
      }
    }
  }
}

export type FormattedAddress = {
  id: string
  shipping_address: string
  shipping_name: string
  shipping_phone: string
  market: string
  marketId: number
  marketName: string
  zoneName: string
  zoneId: number
  distributionCenterId?: number
  name: string
  shipping_number?: string
  shipping_instructions?: string
  customer_address?: string
  isFavorite?: boolean
  geocode: {
    latitude?: number
    longitude?: number
  }
}

export type RadioInput = SelectOption & {
  checked?: boolean
  descriptionText?: string
  image?: string
}

export enum RadioButtonSize {
  NORMAL = '20px',
  SMALL = '16px',
  BIG = '24px',
}

export type MouseEventButton = React.MouseEvent<HTMLButtonElement, MouseEvent>

export type Order = {
  shipping_address: string
  shipping_number: string
  shipping_name: string
  shipping_phone: string
  shipping_instructions: string
  billing: BillingData | null
  payment: number | string | undefined
  date: string | undefined
  marketing: boolean | string | undefined
  terms: boolean | undefined
  addressId?: string
  payment_type?: PaymentTypes
}

export type BillingData = {
  billing_first_name: string
  billing_last_name: string
  billing_city?: string
  billing_city_code?: string
  billing_address: string
  billing_user_id: string
}

export type OrderData = Order & { addressId: string; isQRSelected?: boolean }

export interface ContentfulImageQueryParams {
  fm?: 'jpg' | 'png' | 'webp'
  fl?: 'progressive' | 'png8'
  w?: number
  h?: number
  fit?: 'pad' | 'fill' | 'scale' | 'crop' | 'thumb'
  f?:
    | 'center'
    | 'top'
    | 'right'
    | 'left'
    | 'bottom_right'
    | 'bottom_left'
    | 'top_right'
    | 'top_left'
    | 'bottom_right'
    | 'bottom_left'
    | 'face'
    | 'faces'
  r?: number | 'max'
  q?: number
  bg?: string
}

export enum PromotionType {
  PRICE_REDUCTION = 'price_reduction',
  PERCENTAGE_DISCOUNT = 'percentage_discount',
  AMOUNT_DISCOUNT = 'amount_discount',
  MXN = 'mxn',
  X_UNIT = 'x_unit',
  A_DISCOUNTS_B = 'a_discounts_b',
  A_PERCENTAGE_DISCOUNTS_B = 'a_percentage_discounts_b',
  GIFT_CARD = 'gift_card',
}

export enum TagType {
  cyber = 'Cyber',
  new = '¡Nuevo!',
  outstanding = 'Destacado',
  top_selling = 'Más vendido',
  promo = 'Promo',
  black_friday = 'Black Friday',
  promotion = 'Promoción',
}

export type OrderAfterPayment = {
  items: ByHash
  status: OrderStatus
  total: number
  number: number
  metadata: CLOrder['attributes']['metadata']
  shippingAmountCents: number
  shippingAmountFloat: number
  clayerTotalCents: number
  clayerTotalFloat: number
  couponCode: string | null
  giftCardCode: string | null
  skus_count: number
}

export type SeoTag = {
  title?: string
  description?: string
}

export type ProductPromotion = {
  type: PromotionType
  name: string
  startsAt: string
  startsAtTime: string
  expiresAt: string
  exp: string
  skus: (string | number)[]
  markets: string[]
  productDetailTitle: string
  productDetailDescription: string
  productDetailInfoText: string
  productCardLabel: string
  discountSkus?: string[]
  uuid: string
  discountPercentage?: number
  isRaw: boolean
  discountedProduct?: FormattedProduct
  activationQuantity?: number
  minActivationAmount?: number
  catalogDetail?: string
}

export interface Product {
  skuCode: string
  image: string | null
  title: string
  price: number
  rawPrice: number
  hasDiscount: boolean
  hasStock?: boolean
  discount?: number
  recyclable?: boolean
  returnabilityLabel?: string
  unavailable?: boolean
  isBundle?: boolean
  brandName?: string
  categoryName?: string
  packing?: string
  size?: string
  slugLocation?: string
  netContent?: number
  promotion?: ProductPromotion | null
  isCustomizable?: boolean
  johnnieWalkerTag?: string
  labelId?: string | null
  labelUrl?: string | null
  presale?: boolean
  thumbnail?: string
  isDummy?: boolean
  isGift?: boolean
  tags?: string
  isSuggested?: boolean
  unitName?: string
  flavorName?: string
  sizeType?: string
  sugar?: string
  returnability?: string
  sellUnits?: string
  images?: string[]
  stock?: number
  seo?: SeoTag
  productDescription?: string
  maxProductsPerCart?: number
}

// Region types
export type DistrictState = {
  checkZone: boolean
  hasError: boolean
  selectedZone: Zone | undefined
}

export type DistrictProps = {
  state: DistrictState
  dispatcher: React.Dispatch<React.SetStateAction<DistrictState>>
}

// Bolivia payment types
export enum PaymentTypes {
  // CL
  WEBPAY = 'webpay',
  CHEK = 'chek',
  MACH = 'mach',
  FLOW_PAY = 'flowpay',
  // BO
  REDENLACE = 'redenlace',
  QR = 'qr-contra-entrega',
  EFECTIVO_CONTRA_ENTREGA = 'efectivo-contra-entrega',
  TARJETA_CONTRA_ENTREGA = 'tarjeta-contra-entrega',
  CONTRA_ENTREGA = 'contra-entrega',
  GIFT_CARD = 'gift-card',
  GIFT_CARD_WEBPAY = 'gift-card/webpay',
  GIFT_CARD_MACH = 'gift-card/mach',
  GIFT_CARD_CHEK = 'gift-card/chek',
  GIFT_CARD_FLOWPAY = 'gift-card/flowpay',
}

export enum PaymentStatus {
  PAID = 'pagado',
  PENDING = 'por-pagar',
}

export type PaymentMethodType = RadioInput & { QRID?: number }

export type PaymentMethodsObject = {
  types: PaymentMethodType[]
  options: RadioInput[]
}

export type Maps = google.maps.FusionTablesMarkerOptions &
  google.maps.FusionTablesMouseEvent &
  google.maps.FusionTablesCell &
  google.maps.FusionTablesPolygonOptions

export interface WindowWithMaps extends Window {
  google: {
    maps: Maps | null
  }
}

// Cart Types
export interface ProductCart extends Product {
  quantity: number
  rawPrice: number // It must always exist in the shopping cart
  discount: number
  lineItemId?: string
  stock?: number
  onNavigate?: () => void
  promotionDetail?: PromotionDetail
  isGift?: boolean
  discountQuantity?: number
}

export type ByHash = { [K: string]: ProductCart }

export type TotalsObject = {
  globalQuantity: number
  globalRawTotal: number
  globalTotal: number
  globalTotalDiscounted: number
  globalCouponDiscounted: number
}

export type Promotions = { [K: string]: PromotionDetail }

export type PromotionDetail = {
  discountAmount?: number
  labels?: string[]
  names?: string[]
  isGift?: boolean
  discountQuantity?: number
  sku?: string
  total?: number
  promotion?: ProductPromotion
  lineItemId?: string
}

export type ProductCardType = 'small' | 'large'

export type ApplyCouponParams = {
  code: string
  orderId: string
  market: string
  country: Country
  currentMarket: Market
  distributionCenterSlug: string
}

export type ApplyGiftCardParams = {
  giftCard: string
  orderId: string
  marketNumber: number
  country: Country
  currentMarket: Market
}

export type RemoveCouponOrGiftCardParams = {
  code: string
  orderId: string
  country: Country
  currentMarket: Market
  distributionCenterSlug: string
}

export type RemoveGiftCardParams = RemoveCouponOrGiftCardParams

export type ActionApplyCoupon = ({
  ...args
}: Omit<ApplyCouponParams, 'marketNumber'>) => Promise<void | {
  discountedAmount: number
  isRaw?: boolean
  adjustment?: number
  interactionType?: string
}>
export type ActionRemoveCoupon = (params: RemoveCouponOrGiftCardParams) => Promise<void>

export type ActionApplyGiftCard = ({
  ...args
}: Omit<ApplyGiftCardParams, 'marketNumber'>) => Promise<void | {
  discountedAmount: number
  isRaw?: boolean
  adjustment?: number
  interactionType?: string
}>

export type ActionRemoveGiftCard = (params: RemoveGiftCardParams) => Promise<void>

export enum AuthFormType {
  GUEST = 'guest',
  SIGNUP = 'signup',
  SIGNIN = 'signin',
}

export type StoredCustomer = {
  name: string
  email: string
  clayerId?: string
  favoriteSkus?: number[]
  slugLocation: string
  skusAvailabilityList?: number[]
}

export type vamosXOtraContactParams = {
  localName: string
  address: string
  region?: string
  contact: string
  email: string
  description: string
}

export type eventQuoteContactParams = {
  name: string
  dni: string
  city: string
  email: string
  description: string
}

export type eventQuoteParams = {
  event: string
  date: string
  inviteNumber: string
}

export enum CustomerGroups {
  'BLOQUEADO' = 'BLOQUEADO',
  'MAXIMO1' = 'MAXIMO-1',
  'MAXIMO3' = 'MAXIMO-3',
}

export type CustomerGroup = {
  id: string
  name: CustomerGroups
  type: string
}
