import styled, { css } from 'styled-components'
import React, { useEffect, useRef, useState } from 'react'
import secrets from '@ecommerce/chile-customer-webapp/src/config/secrets'
import { breakpoints } from '../../../utils/breakpoints'
import { useShoppingCart } from '../../../context/ShoppingCart'
import { Product } from '../../../types'
import {
  sendAddProductToGTM,
  sendRemoveProductToGTM,
  Button as Btn,
  CartStockErrorHandler,
  showToast,
  hexToRGBA,
} from '../../../..'
import { useDisableAddProductButton } from '../../../hooks/useDisableAddProductButton'
import PromotionHelpText, { StyleProps as PromotionHelpTextProps } from './PromotionHelpText'
import { useSuggestedProducts } from '../../../context/Cart/suggested.context'

const { COUNTRY } = secrets

const MinusIcon = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2.77051C6.47715 2.77051 2 7.24766 2 12.7705C2 18.2934 6.47715 22.7705 12 22.7705C17.5228 22.7705 22 18.2934 22 12.7705C22 7.24766 17.5228 2.77051 12 2.77051ZM15.6 11.7705C16.1523 11.7705 16.6 12.2182 16.6 12.7705C16.6 13.2833 16.214 13.706 15.7166 13.7638L15.6 13.7705H8.4C7.84772 13.7705 7.4 13.3228 7.4 12.7705C7.4 12.2577 7.78604 11.835 8.28338 11.7772L8.4 11.7705H15.6Z"
      fill="white"
    />
  </svg>
)

const PlusIcon = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12.7705C2 7.24766 6.47715 2.77051 12 2.77051C17.5228 2.77051 22 7.24766 22 12.7705C22 18.2934 17.5228 22.7705 12 22.7705C6.47715 22.7705 2 18.2934 2 12.7705ZM12.9933 9.05389C12.9355 8.55655 12.5128 8.17051 12 8.17051C11.4477 8.17051 11 8.61822 11 9.17051V11.7705H8.39999L8.28337 11.7772C7.78603 11.835 7.39999 12.2577 7.39999 12.7705C7.39999 13.3228 7.84771 13.7705 8.39999 13.7705H11V16.3705L11.0067 16.4871C11.0645 16.9845 11.4872 17.3705 12 17.3705C12.5523 17.3705 13 16.9228 13 16.3705V13.7705H15.6L15.7166 13.7638C16.214 13.706 16.6 13.2833 16.6 12.7705C16.6 12.2182 16.1523 11.7705 15.6 11.7705H13V9.17051L12.9933 9.05389Z"
      fill="white"
    />
  </svg>
)

const defaultStyles = css`
  @media (${breakpoints.desktop.min}) {
    height: 40px;
    width: 160px;
    span {
      font-size: 17px;
    }
  }
`

type TypeButton = 'small' | 'large'

const Button = styled.button<{ typeButton?: TypeButton; customButtonColor?: boolean }>`
  touch-action: manipulation;
  border-radius: ${(props) => props.theme.borderRadius};
  height: 35px;
  width: 125px;
  span {
    font-size: 13px;
    font-weight: bold;
  }
  background: ${({ disabled, theme, customButtonColor }) =>
    disabled
      ? theme.colors.platinum60
      : (customButtonColor && theme.colors.buttonBackgroundPrimaryColor) || theme.colors.red};
  color: ${({ theme, customButtonColor }) =>
    (customButtonColor && theme.colors.buttonPrimaryColor) || theme.colors.white};
  font-size: 13px;
  font-weight: bold;
  outline: none;
  border: none;
  cursor: ${(props) => (props.disabled ? 'initial' : 'pointer')};
  &:hover {
    opacity: ${(props) => (props.disabled ? '1' : '0.7')};
  }
  &.disabled {
    background: ${({ theme }) => theme.colors.platinum60};
    &:hover {
      opacity: 1;
    }
  }

  ${(props) => (props.typeButton === 'large' ? defaultStyles : '')};
`

const WrapperActionButton = styled.div<{ typeButton?: TypeButton }>`
  touch-action: manipulation;
  border-radius: ${(props) => props.theme.borderRadius};
  height: 35px;
  width: 100%;
  min-width: 114px;
  padding-inline: 15px;
  padding-block: 15px;

  span {
    font-size: 13px;
    font-weight: bold;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => hexToRGBA(props.theme.colors.black80, 0.75)};
  justify-items: center;
  .quantity-label {
    font-size: 16px;
    color: ${(props) => props.theme.colors.white};
  }

  ${(props) => (props.typeButton === 'large' ? defaultStyles : '')};
  @media screen and (${breakpoints.tabletPortrait.min}) {
    .quantity-label {
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.25px;
    }
  }
`

const Wrapper = styled.div<{ justifyButton: boolean }>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: 1;

  ${({ justifyButton }) =>
    justifyButton &&
    css`
      justify-content: center;
    `}

  .info-message {
    margin: 0 auto;
    color: ${({ theme }) => theme.colors.error};
    > svg {
      fill: ${({ theme }) => theme.colors.error};
    }
  }
`

const ActionButton = styled.button<{ isDisabled?: boolean }>`
  touch-action: manipulation;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  > svg {
    fill: ${({ theme, isDisabled }) => (isDisabled ? theme.colors.platinum80 : theme.colors.white)};
  }
`

interface Props {
  typeButton?: TypeButton
  quantity: number
  product: Product
  className?: string
  isLoading?: boolean
  notify?: boolean
  stockErrorHandler?: CartStockErrorHandler
  disabled?: boolean
  promotionActivationQuantity?: number
  promotionHelpTextProps?: PromotionHelpTextProps
  isLoadingNotify?: boolean
  customButtonColor?: boolean
  justifyButton?: boolean
  isSuggested?: boolean
  showHelpText?: boolean
}

function AddProductButton(props: Props) {
  const {
    className,
    quantity,
    product,
    typeButton = 'small',
    isLoading,
    stockErrorHandler,
    disabled = false,
    promotionActivationQuantity,
    promotionHelpTextProps,
    notify = false,
    isLoadingNotify,
    customButtonColor,
    justifyButton,
    isSuggested = false,
    showHelpText = true,
  } = props

  const firestoreUpdateTimeout = useRef<number>(0)
  const [productQuantity, setProductQuantity] = useState(quantity)
  const [isAddingPlayStationProduct, setIsAddingPlayStationProduct] = useState(false)
  const { addProduct, subtractProduct } = useShoppingCart()
  const { setIsAddedSuggestedProduct } = useSuggestedProducts()

  const disabledAddButton = useDisableAddProductButton({
    skuCode: product.skuCode,
    quantity: productQuantity,
  })

  const isDisabled = disabled || disabledAddButton

  const isBO = COUNTRY === 'BO'

  const updateProduct = async () => {
    const quantityToAdd = productQuantity - quantity
    if (quantityToAdd > 0) {
      if (product.categoryName === secrets.mainPlaystationCategoryName) setIsAddingPlayStationProduct(true)
      sendAddProductToGTM(product, isBO, quantityToAdd)
      await addProduct({ product, quantity: quantityToAdd }, stockErrorHandler)
      return
    }
    const quantityToSubstract = quantity - productQuantity
    if (quantityToSubstract > 0) {
      sendRemoveProductToGTM([{ ...product, quantity: quantityToSubstract }], isBO)
      subtractProduct(product.skuCode, quantityToSubstract)
    }
  }

  const clearFirestoreUpdateTimeout = () => {
    if (firestoreUpdateTimeout.current) {
      clearTimeout(firestoreUpdateTimeout.current)
    }
  }

  useEffect(() => {
    if (quantity === productQuantity) {
      return
    }

    if (product.categoryName === secrets.mainPlaystationCategoryName) {
      updateProduct()
      return
    }

    clearFirestoreUpdateTimeout()

    firestoreUpdateTimeout.current = window.setTimeout(updateProduct, 300)

    return () => {
      clearFirestoreUpdateTimeout()
    }
  }, [productQuantity])

  useEffect(() => {
    if (setIsAddingPlayStationProduct) {
      const timeout = setTimeout(() => {
        setIsAddingPlayStationProduct(false)
      }, 300)
      return () => clearTimeout(timeout)
    }
  }, [isAddingPlayStationProduct])

  useEffect(() => {
    if (quantity !== productQuantity) setProductQuantity(quantity)
  }, [quantity])

  function onIncrease() {
    if (isDisabled) return
    if (isSuggested) setIsAddedSuggestedProduct(true)
    if (product.maxProductsPerCart && productQuantity >= product.maxProductsPerCart) {
      showToast({
        content: `Puedes llevar un máximo de ${product.maxProductsPerCart} unidades ${product.title} por compra.`,
        type: 'error',
      })
      return
    }
    setProductQuantity((qty) => qty + 1)
  }

  function onDecrease() {
    setProductQuantity((qty) => qty - 1)
  }

  if (isLoading) {
    return <Btn disabled isDisabled isLoading className={className} />
  }

  if (product.unavailable && !isLoadingNotify) {
    return (
      <Button
        typeButton={typeButton}
        disabled
        className={`disabled ${className}`}
        customButtonColor={!!customButtonColor}
      >
        No disponible
      </Button>
    )
  }

  function onPreventAndStop(e: React.MouseEvent) {
    e.preventDefault()
    e.stopPropagation()
    return (fn?: () => void) => {
      if (fn) fn()
    }
  }

  if (productQuantity === 0)
    return (
      <Button
        className={`${className} ${className}-quantity-is-0`}
        typeButton={typeButton}
        onClick={(e) => onPreventAndStop(e)(onIncrease)}
        onMouseUp={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        onMouseDown={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        disabled={disabled || notify}
        customButtonColor={!!customButtonColor}
      >
        Agregar
      </Button>
    )

  const shouldActivatePromotion =
    promotionActivationQuantity && productQuantity <= (promotionActivationQuantity ?? 0) - 1

  return (
    <Wrapper className={`${className}-product-add`} justifyButton={!!justifyButton}>
      <WrapperActionButton
        data-test="product-add"
        className={className}
        typeButton={typeButton}
        onClick={(e) => onPreventAndStop(e)}
        onMouseUp={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <ActionButton
          data-test="product-add-decrease"
          className="action-button"
          type="button"
          onClick={(e) => onPreventAndStop(e)(onDecrease)}
          onMouseUp={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <MinusIcon />
        </ActionButton>
        <span className="quantity-label">{productQuantity}</span>
        <ActionButton
          data-test="product-add-increase"
          className="action-button"
          type="button"
          onClick={(e) => onPreventAndStop(e)(onIncrease)}
          onMouseUp={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
          isDisabled={isDisabled || isAddingPlayStationProduct}
          disabled={isDisabled || isAddingPlayStationProduct}
        >
          <PlusIcon />
        </ActionButton>
      </WrapperActionButton>
      {showHelpText && shouldActivatePromotion && promotionActivationQuantity && (
        <PromotionHelpText
          {...(promotionHelpTextProps ?? {})}
          text={`Faltan ${promotionActivationQuantity - productQuantity} para la ${isBO ? 'oferta' : 'promoción'}`}
        />
      )}
    </Wrapper>
  )
}

export default AddProductButton
