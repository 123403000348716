import React, { useMemo, useState } from 'react'
import { getAuth, useResolution } from '@ecommerce/shared'
import loadable from '@loadable/component'
import { Tag } from '@ecommerce/chile-customer-webapp/src/components/Tag'
import NotifyModal from '@ecommerce/chile-customer-webapp/src/components/ProductDetail/Detail/NotifyModal'
import { Icon } from '@ecommerce/chile-customer-webapp/src/components/Icon/Icon'
import AddProductButton from '../AddProductButton'
import { DragState, initialDragState, startDragCheck, endDragCheck } from './dragHandler'
import Image from '../../atoms/Image'
import { useShoppingCart, CartStockErrorHandler } from '../../../context/ShoppingCart'
import { Product, ProductCardType } from '../../../types'
import { useLocation } from '../../../context/Location'
import { toCssPrefix } from '../../../utils/format'
import { ProductCardWrapper, cssPrefix as prefix, HeartIcon, ReturnabilityTag } from './styled'
import { PromotionTag } from './PromotionTag'
import secrets from '../../../config'
import useFavoriteProducts from '../../../hooks/useFavoriteProducts'
import NotifyButton from './NotifyButton'
import LimitedHelpText from './LimitedHelpText'
import { getReturnabilityType, ReturnabilityType } from '../../../utils/returnability'

interface Props {
  product: Product
  type?: ProductCardType
  onNavigate?: () => void
  stockErrorHandler?: CartStockErrorHandler
  bgColor?: string
  className?: string
  showAddButton?: boolean
  showPrice?: boolean
  useDragHandler?: boolean
  hasStock?: boolean
  notify?: boolean
  isLoading?: boolean
  isSuggested?: boolean
  isHome?: boolean
}

const { cssPrefix, toPrefix } = toCssPrefix(prefix)

const loadableOptions = {
  ssr: false,
}

const ModalBackground = loadable(() => import('@ecommerce/shared'), {
  resolveComponent: (components) => components.ModalBackground,
  ...loadableOptions,
})

const ProductCard = ({
  type,
  onNavigate,
  product,
  bgColor,
  stockErrorHandler,
  showAddButton = true,
  showPrice = product.hasStock ?? !product.unavailable,
  className = '',
  useDragHandler = true,
  hasStock = product.hasStock ?? !product.unavailable,
  notify = false,
  isLoading = false,
  isSuggested = false,
  isHome = false,
}: Props) => {
  const {
    skuCode,
    thumbnail,
    image,
    title,
    price,
    rawPrice,
    discount,
    unavailable,
    recyclable = false,
    isBundle,
    hasDiscount,
    returnabilityLabel,
    isCustomizable,
    isDummy,
    tags,
  } = product

  const [showAddNotifyModal, setShowAddNotifyModal] = useState(false)
  const { isMobile, isTablet, isDesktop } = useResolution()

  const isAuth = getAuth()
  const {
    state: { byHash },
  } = useShoppingCart()
  const { toCurrency } = useLocation()

  const {
    shouldRenderProductCardButton: shouldRenderAddFavoriteButton,
    isFavorite,
    onButtonClick,
  } = useFavoriteProducts({
    sku: Number(skuCode),
  })

  const productAdded = byHash[skuCode || '']
  const quantity = productAdded ? productAdded.quantity : 0
  const [dragState, setDragState] = useState<DragState>(initialDragState)

  const isForPreSale = Number(skuCode) === secrets.JW_PRESALE_SKU

  const onAddNotify = () => {
    setShowAddNotifyModal(true)
  }

  const returnabilityType = useMemo(() => {
    return getReturnabilityType({ returnabilityLabel })
  }, [returnabilityLabel])

  return (
    <ProductCardWrapper
      type={type}
      data-test="product-card"
      onMouseDown={(e) => setDragState(startDragCheck(e))}
      onMouseUp={(e) => {
        setDragState(
          endDragCheck(e, dragState, () => {
            if (onNavigate) onNavigate()
          }),
        )
      }}
      onTouchStart={(e) => e.preventDefault()}
      onClick={() => {
        if (hasStock) {
          return !useDragHandler && onNavigate && !isDummy ? onNavigate() : null
        }
        return null
      }}
      off={unavailable}
      isRecyclable={recyclable}
      isBundle={isBundle}
      hasDiscount={hasDiscount}
      hasStock={hasStock}
      bgColor={bgColor}
      className={`${cssPrefix} ${className}`}
      hasLabel={recyclable || isCustomizable}
      isDummy={isDummy}
      isLoading
      notify={notify}
      isAuth={isAuth}
      tag={tags || (product?.promotion?.catalogDetail && product?.promotion?.productCardLabel)}
      isHome={isHome}
    >
      {showAddNotifyModal && (
        <ModalBackground>
          <NotifyModal product={product} onCancel={() => setShowAddNotifyModal(false)} />
        </ModalBackground>
      )}
      <div className={toPrefix('product-card-content')}>
        <div className={toPrefix('left-content')}>
          {(isTablet || isDesktop || type === 'large') && (
            <>
              {tags && <Tag type={type} tag={tags} className={toPrefix('tag')} />}
              {product?.promotion?.catalogDetail && product?.promotion?.catalogDetail.length > 0 && (
                <PromotionTag
                  type="large"
                  className={toPrefix('tag')}
                  product={product}
                  promotion={product?.promotion}
                />
              )}
            </>
          )}
          <Image className={toPrefix('image')} draggable="false" src={thumbnail ?? image} alt={title} />
          {isCustomizable && !recyclable ? (
            <span className={toPrefix('custom-label-support-text')}>PERSONALÍZAME</span>
          ) : null}
          {isForPreSale && !recyclable && !isCustomizable ? (
            <span className={toPrefix('custom-label-support-text is-presale')}>PREVENTA</span>
          ) : null}
        </div>
        <div className={toPrefix('content')}>
          <div className={toPrefix('content-top')}>
            {isMobile && type === 'small' && (
              <>
                {tags && <Tag type={type} tag={tags} />}
                {product?.promotion?.catalogDetail && product?.promotion?.catalogDetail.length > 0 && (
                  <PromotionTag
                    className={toPrefix('tag')}
                    type="small"
                    product={product}
                    promotion={product?.promotion}
                  />
                )}
              </>
            )}
            <p className={toPrefix('title')}>{title}</p>
          </div>
          <div className={toPrefix('content-bottom')}>
            {showPrice && !notify && (
              <div className={toPrefix('discount-wrapper')}>
                {discount && discount > 0 ? <div className={toPrefix('discount-badge')}>{`-${discount}%`}</div> : null}
                <p className={toPrefix('discount-price')}>{hasDiscount ? toCurrency(rawPrice) : null}</p>
              </div>
            )}
            {showPrice && notify && (
              <div className={toPrefix('discount-wrapper')}>
                {discount && discount > 0 ? <div className={toPrefix('discount-badge')}>{`-${discount}%`}</div> : null}
                <p className={toPrefix('discount-price')}>{hasDiscount ? toCurrency(rawPrice) : null}</p>
              </div>
            )}
            <div className={toPrefix('bottom')}>
              {showPrice && !notify && <p className={toPrefix('final-price')}>{toCurrency(price > 1 ? price : 0)}</p>}
              {!showPrice && !notify && <NotifyButton onAddNotify={onAddNotify} isAuth />}
            </div>
            <div className={toPrefix('action-buttons')}>
              {showAddButton && (
                <AddProductButton
                  isSuggested={isSuggested}
                  isLoadingNotify={isLoading}
                  notify={notify}
                  stockErrorHandler={stockErrorHandler}
                  typeButton="large"
                  product={{ ...product, presale: isForPreSale }}
                  className={toPrefix('add-button')}
                  quantity={quantity}
                  disabled={isDummy || ((!!productAdded?.labelUrl || isForPreSale) && quantity === 1)}
                  promotionActivationQuantity={product?.promotion?.activationQuantity}
                />
              )}
              {shouldRenderAddFavoriteButton && !isDummy && hasStock && !notify ? (
                <button
                  onClick={onButtonClick}
                  onMouseUp={(e) => e.stopPropagation()}
                  onMouseDown={(e) => e.stopPropagation()}
                  className={`${toPrefix('add-favorite')} ${isFavorite && 'is-favorite'}`}
                  type="button"
                  title="Agregar a Mis Favoritos"
                >
                  <HeartIcon />
                </button>
              ) : null}
            </div>
            {product.maxProductsPerCart && quantity >= product.maxProductsPerCart && (
              <LimitedHelpText text={`Máx. ${product.maxProductsPerCart} unidades por compra.`} />
            )}
          </div>
        </div>
      </div>
      <ReturnabilityTag returnabilityType={returnabilityType}>
        {returnabilityType === 'RETURNABLE' ? <Icon iconId="returnability" size={16} /> : null}
        {returnabilityLabel}
      </ReturnabilityTag>
    </ProductCardWrapper>
  )
}

export default ProductCard

export { ProductCardWrapper }
