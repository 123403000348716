import { AxiosResponse } from 'axios'
import secrets from '@ecommerce/chile-customer-webapp/src/config/secrets'
import fetch from './fetch'
import { Country, Product, ProductPromotion, SeoTag, TagType } from '../../types'
import { Market } from '../Location/cities'
import { slugify } from '../../utils/format'
import { log } from '../../utils/log'

export interface AlgoliaProduct extends Product {
  sku: string
  name: string
  imgHeroHi: string
  imgShotHi: string
  imgNutriHi: string
  contentfulImages?: string[]
  imageUrl?: string
  imagePreviewUrl?: string
  imgProductLabelingHi?: string
  location?: string
  returnability?: string
  mainCategoryName?: string
  variationName?: string
  size?: string
  packagingName?: string
  itemType?: string
  slugLocation: string
  price: number
  originalPrice: number
  hasDiscount: boolean
  brandName?: string
  promotion?: ProductPromotion
  hasStock: boolean
  johnnieWalkerTag?: string
  isDummy?: boolean
  tags?: TagType
  unitName?: string
  flavorName?: string
  sizeType?: string
  sugar?: string
  sellUnits?: string
  stock: number
  heroName?: string
  heroDescription?: string
  heroNamePack?: string
}

interface AlgoliaResponse<T> {
  hits: T[]
  page: number
  nbPages: number
  hitsPerPage: number
}

type ProductResponse = AlgoliaResponse<AlgoliaProduct>

export interface FormattedProduct {
  skuCode: string
  image: string
  title: string
  price: number
  rawPrice: number
  hasDiscount: boolean
  discount?: number
  recyclable: boolean
  categoryName?: string
  brandName?: string
  variationName?: string
  size?: string
  packing?: string
  isBundle: boolean
  slugLocation: string
  originalPrice: number
  location?: string
  promotion?: ProductPromotion
  johnnieWalkerTag?: string
  isCustomizable?: boolean
  thumbnail?: string
  netContent?: number
  returnabilityLabel?: string
  unavailable?: boolean
  isDummy?: boolean
  tags?: TagType
  hasStock?: boolean
  unitName?: string
  flavorName?: string
  sizeType?: string
  sugar?: string
  returnability?: string
  sellUnits?: string
  images: string[]
  stock: number
  seo?: SeoTag
  productDescription?: string
  maxProductsPerCart?: number
}

export type AlgoliaAttributes = { hitsPerPage?: number; page?: number; facetFilters?: string[][] }

export const toFormattedProduct = (product: AlgoliaProduct, includeLocation?: boolean) => {
  const productImage =
    product.imageUrl || (product.contentfulImages ? product.contentfulImages?.[0] : product.imgHeroHi)
  const productImages = [
    product.imgHeroHi ?? product.imageUrl,
    product.imgShotHi,
    product.imgNutriHi,
    product.imgProductLabelingHi ?? '',
  ].filter(Boolean)

  const percent =
    product.originalPrice && product.originalPrice !== product.price
      ? Math.round(((product.originalPrice - product.price) / product.originalPrice) * 100)
      : undefined
  const formattedProduct: FormattedProduct = {
    skuCode: product.sku,
    image: productImage,
    thumbnail: product.imagePreviewUrl || productImage,
    title: product.name.charAt(0) + product.name.slice(1).toLowerCase(),
    price: product.price,
    rawPrice: product.originalPrice && product.originalPrice !== product.price ? product.originalPrice : 0,
    hasDiscount: product.hasDiscount,
    discount: percent,
    recyclable: product.returnability === 'RETORNABLE',
    categoryName: product.mainCategoryName,
    variationName: product.variationName,
    brandName: product.brandName,
    size: product.size,
    netContent: product.netContent,
    packing: product.packagingName,
    isBundle: product.itemType === 'bundle',
    slugLocation: product.slugLocation,
    originalPrice: product.originalPrice,
    returnabilityLabel: product.returnabilityLabel,
    promotion: product?.promotion,
    unavailable: !product.hasStock,
    johnnieWalkerTag: product?.johnnieWalkerTag,
    isDummy: product?.isDummy,
    isCustomizable: !!product?.johnnieWalkerTag,
    tags: product.tags,
    hasStock: product.hasStock,
    unitName: product.unitName,
    flavorName: product.flavorName,
    sizeType: product.sizeType,
    sugar: product.sugar,
    returnability: product.returnability,
    sellUnits:
      product?.sellUnits && parseInt(product?.sellUnits, 10) > 1
        ? `Pack de ${product?.sellUnits}`
        : `${product?.sellUnits} unidad`,
    images: productImages,
    ...(includeLocation ? { location: product.location } : {}),
    stock: product.stock,
    seo: {
      title: product?.heroName,
      description: product?.heroNamePack,
    },
    productDescription: product?.heroDescription,
    maxProductsPerCart: product?.maxProductsPerCart,
  }

  return formattedProduct
}

export const getProducts = async (
  filters: string,
  attributes?: AlgoliaAttributes,
  includeLocation?: boolean,
): Promise<FormattedProduct[]> => {
  try {
    const { data }: AxiosResponse<ProductResponse> = await fetch('/query', {
      data: { filters, ...attributes },
      method: 'POST',
    })
    if (!Array.isArray(data?.hits)) {
      return []
    }
    return data.hits.map((product) => toFormattedProduct(product, includeLocation))
  } catch (e) {
    log.error(e)
    return e
  }
}

export const getAllProducts = async (filters: string, attributes?: AlgoliaAttributes, includeLocation?: boolean) => {
  try {
    const { data }: AxiosResponse<ProductResponse> = await fetch('/query', {
      data: { filters, ...attributes },
      method: 'POST',
    })
    const { nbPages, hits } = data
    if (!Array.isArray(hits)) {
      return []
    }
    // if (!nbPages) throw new Error(`No products found for filters ${filters}`)
    const firstPage = hits.map((product) => toFormattedProduct(product, includeLocation))
    if (nbPages === 1) return firstPage
    const pages = Array.from(Array(nbPages).keys()).slice(1)
    const promises = pages.map((page: number) => getProducts(filters, { ...attributes, page }, includeLocation))
    const newData = await Promise.all(promises)
    return [...firstPage, ...(newData && newData.flat ? newData.flat() : [])]
  } catch (e) {
    log.error(e)
    throw new Error(e)
  }
}

export interface AlgoliaProductImages {
  sku: string
  imgHeroHi: string
  imgShotHi: string
  imgNutriHi: string
  contentfulImages?: string[]
}

export const getProductImages = async (
  sku_codes: string[],
  city: Market,
): Promise<Record<string, AlgoliaProductImages>> => {
  const payload = {
    filters: `slugLocation:${slugify(
      city.distributionCenters[0].commerceLayer.stockLocation.name,
    )} AND (sku=${sku_codes.join(' OR sku=')})`,
    attributesToRetrieve: ['contentfulImages', 'imgHeroHi', 'imgShotHi', 'imgNutriHi', 'sku'],
  }
  const { data } = await fetch('/query', { data: payload, method: 'POST' })
  return data.hits.reduce(
    (hash: Record<string, AlgoliaProductImages>, product: AlgoliaProductImages) => ({
      ...hash,
      [product.sku]: product,
    }),
    {},
  )
}

function getFilterBySkus(slugLocation: string, skus: string[]) {
  const skuCartEmpty = skus.join(' OR sku=')
  return `slugLocation:${slugLocation} AND (sku=${skuCartEmpty})`
}

function getFilterBySkusWithStock(slugLocation: string, skus: string[]) {
  const skuCartEmpty = skus.join(' OR sku=')
  return `slugLocation:${slugLocation} AND (sku=${skuCartEmpty}) AND (hasStock:true)`
}

type getProductsType = {
  slugLocation: string
  skus: string[]
  attributes?: AlgoliaAttributes
  isSuggestedQuery?: boolean
}
export const getProductsBySkus = async ({ slugLocation, skus, attributes, isSuggestedQuery }: getProductsType) => {
  const filter = isSuggestedQuery ? getFilterBySkusWithStock(slugLocation, skus) : getFilterBySkus(slugLocation, skus)
  return getProducts(filter, attributes)
}

export const getAllProductsBySlugLocation = async (slugLocation: string) => {
  const filters = `slugLocation:${slugLocation}`
  const hitsPerPage = 1000
  const products = (await getAllProducts(filters, { hitsPerPage }, true)) as AlgoliaProduct[]
  return products
}
